require('prismjs');
require('prismjs/themes/prism.css');
require('prismjs/components/prism-java');
require('prismjs/components/prism-scala');
require('prismjs/components/prism-dart');
require('prismjs/components/prism-git');
require('prismjs/components/prism-nginx');
require('prismjs/components/prism-python');
require('prismjs/components/prism-sass');
require('prismjs/components/prism-csharp');

const { shouldUpdateScroll } = require('./src/gatsby/scrollUpdate');

exports.shouldUpdateScroll = shouldUpdateScroll;
