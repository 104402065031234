module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KV7MXV3","gtmAuth":"FYFVAajB-NuzpkSeR5vcng","gtmPreview":"env-1","routeChangeEventName":"s4n-decoded-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["Cambria"],"urls":["/fonts/fonts.css"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
