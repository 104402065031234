const SEARCHS_TO_IGNORE_SCROLL = ['page'];

function shouldUpdateScroll({ routerProps: { location } }) {
  const hasAnySearchParameter = SEARCHS_TO_IGNORE_SCROLL.some((currentSearch) => {
    const isTheCurrentOne = location.href.includes(currentSearch);
    return isTheCurrentOne;
  });

  if (hasAnySearchParameter) return false;

  return true;
}

module.exports = {
  shouldUpdateScroll,
};
